import Image from 'next/image';
import { chakra, Center, VStack, useMediaQuery } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { LayoutContainer } from '@components';
import { motion, isValidMotionProp, useAnimation } from 'framer-motion';

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and the children prop to be forwarded.
   * All other chakra props not matching the motion props will still be forwarded.
   */
  shouldForwardProp: prop => isValidMotionProp(prop) || prop === 'children'
});

type Logo = {
  src: string;
  alt: string;
  id: number;
  height: number;
  width: number;
};

const logos: Logo[] = [
  {
    src: '/images/logos/seam.svg',
    alt: 'Seam',
    height: 64,
    width: 180,
    id: 8
  },
  {
    src: '/images/logos/grafbase.svg',
    alt: 'Grafbase',
    height: 80,
    width: 150,
    id: 3
  },
  {
    src: '/images/logos/candor.svg',
    alt: 'Candor',
    height: 64,
    width: 180,
    id: 2
  },
  {
    src: '/images/logos/covalent.svg',
    alt: 'Covalent',
    height: 100,
    width: 140,
    id: 10
  },
  {
    src: '/images/logos/pano.svg',
    alt: 'Pano',
    height: 80,
    width: 130,
    id: 9
  },
  {
    src: '/images/logos/blueground.svg',
    alt: 'Blueground',
    height: 64,
    width: 180,
    id: 1
  },
  {
    src: '/images/logos/candor.svg',
    alt: 'Candor',
    height: 80,
    width: 100,
    id: 2
  },
  {
    src: '/images/logos/grafbase-text.svg',
    alt: 'Grafbase',
    height: 64,
    width: 180,
    id: 3
  },
  {
    src: '/images/logos/honeylove.svg',
    alt: 'Honeylove',
    height: 200,
    width: 200,
    id: 4
  },
  {
    src: '/images/logos/sassgrid.svg',
    alt: 'SaaSGrid',
    height: 80,
    width: 120,
    id: 5
  },
  {
    src: '/images/logos/savvi.svg',
    alt: 'Savvi',
    height: 70,
    width: 100,
    id: 6
  },
  {
    src: '/images/logos/keywordsearch.svg',
    alt: 'Keyword Search',
    height: 140,
    width: 200,
    id: 7
  },
  {
    src: '/images/logos/logo-criya.svg',
    alt: 'Criya',
    height: 50,
    width: 70,
    id: 11
  },
  {
    src: '/images/logos/logo-level.svg',
    alt: 'Level',
    height: 64,
    width: 180,
    id: 12
  }
];
const ImageBlock = ({
  logo,
  currentLogos,
  setCurrentLogos,
  index,
  animated
}: {
  logo: Logo;
  index: number;
  currentLogos: Logo[];
  setCurrentLogos: (currentLogos: Logo[]) => void;
  animated: boolean;
}): JSX.Element => {
  const controls = useAnimation();

  const freeLogos = logos.filter(logo => {
    return (
      currentLogos.filter(currentLogo => {
        return currentLogo.id == logo.id;
      }).length == 0
    );
  });

  const nextLogo = freeLogos[Math.floor(Math.random() * freeLogos.length)];

  useEffect(() => {
    const sequence = async () => {
      await controls
        .start({
          y: -50,
          opacity: 0,
          transition: { duration: 1 }
        })
        .then(() => {
          const newLogos = [...currentLogos];
          newLogos.splice(index, 1, nextLogo);
          setCurrentLogos(newLogos);
        });

      return await controls.start({
        y: 0,
        opacity: 1,
        transition: { duration: 1 }
      });
    };

    if (animated) {
      sequence();
    }
  }, [animated, controls, currentLogos, index, nextLogo, setCurrentLogos]); // Add all the missing dependencies to the dependency array

  return (
    <ChakraBox
      as={motion.div}
      animate={controls}
      width={36}
      display={'flex'}
      flexDir={'row'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Image
        src={logo?.src}
        alt={`${logo.alt} logo`}
        height={logo.height}
        width={logo.width}
      />
    </ChakraBox>
  );
};

export function PartnerLogos(): JSX.Element {
  const [loading, toggleLoading] = useState<boolean>(false);
  const [isSmallerThan768] = useMediaQuery('(max-width: 768px)');
  const [currentLogos, setCurrentLogos] = useState<Logo[]>(logos.slice(0, 5));
  const [animatedColumn, setAnimatedColumn] = useState<number>(8);

  useEffect(() => {
    const options = isSmallerThan768 ? [0, 1, 2] : [0, 1, 2, 3, 4];
    const innactiveColumns = options.filter(
      option => option !== animatedColumn
    );

    const intervalId = setInterval(() => {
      toggleLoading(true);
      setAnimatedColumn(
        innactiveColumns[Math.floor(Math.random() * options.length)]
      );
      toggleLoading(false);
    }, 4000);

    return () => clearInterval(intervalId);
  }, [animatedColumn, isSmallerThan768]);

  useEffect(() => {
    if (isSmallerThan768) {
      setCurrentLogos(logos.slice(0, 3));
    } else {
      setCurrentLogos(logos.slice(0, 5));
    }
  }, [isSmallerThan768]);

  return (
    <div className="mx-auto flex min-h-[3.25rem] max-w-[76rem] flex-col items-center justify-between space-y-4 px-6 sm:flex-row sm:space-y-0">
      <p className="max-w-[12.5rem] text-center text-[0.875rem] sm:text-left">
        Trusted by fast-growing companies around the world
      </p>
      <div className="flex">
        {!loading &&
          currentLogos.map((logo, index) => (
            <ImageBlock
              logo={logo}
              currentLogos={currentLogos}
              setCurrentLogos={setCurrentLogos}
              index={index}
              animated={animatedColumn === index}
              key={index}
            />
          ))}
      </div>
    </div>
  );
}
