import Image from 'next/image';
import {
  AspectRatio,
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  useDisclosure,
  Box,
  Fade
} from '@chakra-ui/react';
import Link from 'next/link';
import PlayIcon from 'remixicon-react/PlayCircleFillIcon';
import ArrowRightLineIcon from 'remixicon-react/ArrowRightLineIcon';
import ArrowRightUp from 'remixicon-react/ArrowRightUpLineIcon';
import { useEffect, useState } from 'react';
import {
  DemoModal,
  LayoutContainer,
  GridPatternDark
} from '@components/common';
import { useSegment } from '@hooks/use-segment';
import { AnimatePresence, motion } from 'framer-motion';

function HoverButton({
  isShowing,
  onOpen
}: {
  isShowing: boolean;
  onOpen: () => void;
}): JSX.Element {
  const analytics = useSegment();
  const [hidden, setHide] = useState<boolean>(false);

  useEffect(() => {
    if (isShowing) {
      setHide(false);
    }
  }, [isShowing]);

  return (
    <Box
      cursor="pointer"
      pos="relative"
      zIndex={100}
      display={hidden ? 'none' : 'inline-flex'}
      onClick={() => {
        setHide(true);
        onOpen();
        analytics.track('Marketing_Home_Play Video Clicked', {
          surface: 'Marketing',
          location: 'Home'
        });
      }}
    >
      <Box
        borderRadius="50%"
        h="102px"
        w="102px"
        transform={isShowing ? 'scale(1.05)' : ''}
        pos="relative"
      >
        <Icon
          p="6px"
          as={PlayIcon}
          boxSize={'102px'}
          transform={isShowing ? 'scale(1.05)' : ''}
          borderRadius="50%"
          color={isShowing ? 'primary.500' : 'primary.700'}
          zIndex={1}
          transition="all 0.2s"
        />
        <Box
          // bg="rgb(108,71,255, .10)"
          bg="white"
          h={50}
          w={50}
          borderRadius="50%"
          pos="absolute"
          left={7}
          bottom={6}
          zIndex={-1}
        ></Box>
        <Box
          bg="rgb(108,71,255, .10)"
          h={102}
          w={102}
          borderRadius="50%"
          pos="absolute"
          left={0}
          bottom={0}
          zIndex={-1}
        ></Box>
      </Box>

      <Fade in={isShowing}>
        <AspectRatio
          zIndex={2}
          h={10}
          w={10}
          pos="absolute"
          top="14"
          right="-4"
          borderRadius="50%"
          border="white 2px solid"
        >
          <Image
            src="/avatars/james.webp"
            alt=""
            fill
            style={{ borderRadius: '50%' }}
            loading="lazy"
          />
        </AspectRatio>
        <Box
          bg="white"
          h={16}
          w={80}
          borderRadius={24}
          shadow={isShowing ? 'lg' : 'base'}
          pos="absolute"
          top="100%"
          left="-60%"
          zIndex={-1}
        >
          <Flex flexDir={'column'} alignItems="center" justifyContent="center">
            <Text fontSize="xs" fontWeight={'semibold'} mt={4}>
              Watch James build a Clerk app
            </Text>
            <Flex justifyContent="space-between" alignItems="center" w={'42%'}>
              <Text color="gray.500" fontSize="xs">
                {`2 min`}
              </Text>
              <Text color="primary.700" fontSize="sm">
                Watch now <Icon as={ArrowRightLineIcon} />
              </Text>
            </Flex>
          </Flex>
        </Box>
      </Fade>
    </Box>
  );
}

export function Hero(): JSX.Element | null {
  const analytics = useSegment();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isShowing, setIsShowing] = useState<boolean>(false);

  return (
    <LayoutContainer verticalPadding="sm" pos="relative">
      <AspectRatio
        ratio={1400 / 1190}
        w="1400px"
        pos="absolute"
        top="200px"
        pointerEvents="none"
        draggable={false}
        userSelect="none"
      >
        <GridPatternDark />
      </AspectRatio>
      <DemoModal
        isOpen={isOpen}
        onClose={() => {
          setIsShowing(true);
          onClose();
        }}
      />

      <Flex w="full" justifyContent={'center'} mt={{ base: '40px', lg: '0' }}>
        <Link href="/blog/series-a" target="_blank" rel="noopener">
          <Box
            fontFamily={'heading'}
            fontSize={{ base: '12px', lg: '16px' }}
            lineHeight={'19px'}
            color="#818593"
            display={'flex'}
            flexDir={{ base: 'column', sm: 'row' }}
            mb="30px"
            border="0.5px solid #6C47FF"
            borderRadius={'30px'}
            p="8px 18px 8px 16px"
            whiteSpace={'nowrap'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Box fontWeight={700} display="flex">
              <Text mr={{ base: 1, lg: 2 }}>🎉</Text>{' '}
              <Text>Clerk raises $15m Series A led by Madrona.</Text>
            </Box>
            <Box
              as="span"
              textColor="#6C47FF"
              fontWeight={500}
              ml={{ base: 1, lg: 2 }}
              display="flex"
              whiteSpace={'nowrap'}
            >
              {' '}
              Read More
              <Box alignSelf={'center'}>
                <AnimatePresence>
                  <motion.div
                    transition={{
                      ease: 'linear',
                      duration: 2,
                      repeat: Infinity
                    }}
                    animate={{ x: [4, 10, 4] }}
                  >
                    <Icon
                      as={() => (
                        <svg
                          width="6"
                          height="10"
                          viewBox="0 0 6 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1.20835L4.79167 5.00002L1 8.79169"
                            stroke="#6C47FF"
                            strokeWidth="1"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                      h="38px"
                      w="38px"
                      color="#6C47FF"
                    ></Icon>
                  </motion.div>
                </AnimatePresence>
              </Box>
            </Box>
          </Box>
        </Link>
      </Flex>
      <Stack align="center" textAlign="center" spacing={8}>
        <Heading as="h1" size="h1">
          More than authentication.
          <br />
          <Text as="span" color="primary.500">
            Complete user management.
          </Text>
        </Heading>

        <Text fontSize="xl" maxW={{ base: '%80', md: '65%' }}>
          Clerk is more than a "sign-in box." Integrate complete user management
          UIs and APIs, purpose-built for{' '}
          <Link
            href="/solutions/react-authentication"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            React
          </Link>
          ,{' '}
          <Link
            href="/solutions/nextjs-authentication"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            Next.js
          </Link>
          , and the Modern Web.
        </Text>

        <Flex
          flexDir={{ base: 'column', sm: 'row' }}
          align="center"
          justify="center"
          w="full"
          gap={4}
          pos="relative"
        >
          <Link
            legacyBehavior
            href={process.env.NEXT_PUBLIC_DASHBOARD_URL as string}
            passHref
          >
            <Button
              as="a"
              variant="base"
              h={'48px'}
              fontSize="md"
              zIndex={100}
              rightIcon={<ArrowRightUp className="inline h-6 w-6" />}
            >
              Start Building for Free
            </Button>
          </Link>
          <Button
            w={{ base: 'full', sm: 56 }}
            cursor="pointer"
            onClick={() => {
              analytics.track('Marketing_Home_Watch Demo Button  Clicked', {
                surface: 'Marketing',
                location: 'Home'
              });
              onOpen();
            }}
            h={14}
            variant="ghost"
            fontSize="md"
            zIndex={100}
            color="primary.500"
            _hover={{
              color: 'primary.700'
            }}
          >
            <Icon as={PlayIcon} boxSize={12} mx="16px" />
            <Flex
              flexDir={'column'}
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Text>Watch demo</Text>
              <Text color="gray.500" fontSize="xs">
                {`2 min`}
              </Text>
            </Flex>
          </Button>
        </Flex>

        <Box
          onMouseEnter={() => setIsShowing(true)}
          onMouseLeave={() => setIsShowing(false)}
          pos="relative"
        >
          <Box pos="absolute" top="40%" left="0%" right="0%">
            <HoverButton isShowing={isShowing} onOpen={onOpen} />
          </Box>
          <AspectRatio
            mt={{ md: '-60px' }}
            mb={{ md: '-200px' }}
            width="100vw"
            pos="relative"
            zIndex="1"
            maxW="1400px"
          >
            <Box>
              <Image
                src="/images/home/new-hero.png"
                alt=""
                width={2695}
                height={1614}
                priority
              />
            </Box>
          </AspectRatio>
        </Box>
      </Stack>
    </LayoutContainer>
  );
}
