import {
  AspectRatio,
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react';
import { useInView } from 'react-intersection-observer';
import { URLS } from '@lib/constants';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export function DemoModal({ isOpen, onClose }: Props): JSX.Element {
  const { demoVideo } = URLS;

  const [ref, inView] = useInView({
    rootMargin: '500px 0px',
    threshold: 0.25
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        alignSelf="center"
        bg="transparent"
        maxW="1280px"
        p={0}
        shadow="none"
      >
        <ModalBody display="flex" alignItems="center">
          <Box flex={1} pos="relative">
            <AspectRatio
              ref={ref}
              ratio={16 / 9}
              mx="auto"
              rounded="2xl"
              overflow="hidden"
              boxShadow="dropShadow3"
            >
              <>
                {inView && (
                  <iframe
                    src={demoVideo + '?autoplay=1'}
                    title="Clerk Demo"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                )}
              </>
            </AspectRatio>

            <ModalCloseButton
              bg="primary.500"
              color="white"
              rounded="full"
              top={-10}
              right={0}
              _hover={{
                bg: 'primary.700'
              }}
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
