import { NextPage } from 'next';
import { Box, Stack } from '@chakra-ui/react';
import { Hero, Testimonials, WhyClerk, ModernWeb } from '@components/home';
import {
  LayoutContainer,
  PartnerLogos,
  RoundedCorner
} from '@components/common';
import { singlePageProps } from '@lib';
import { colors } from '@theme/foundations';
import { AuthUserComponents, Customization } from '@components/components';

const { background } = colors;

const HomeV2: NextPage = (): JSX.Element | null => {
  return (
    <Box overflow="hidden" pos="relative">
      <Box
        bgGradient="linear(to-br, #E8E4FF 0%, white 40%, #E8E4FF 100%)"
        pt="80px"
        pos="relative"
        zIndex={1}
      >
        <Hero />
        <PartnerLogos />
      </Box>
      <LayoutContainer bg="background" pos="relative" zIndex={1}>
        <RoundedCorner
          canvasHeight={160}
          bg={background}
          yPosition="top"
          xPosition="left"
        />
        <RoundedCorner
          canvasHeight={160}
          bg={background}
          yPosition="top"
          xPosition="right"
        />
        <RoundedCorner
          canvasHeight={160}
          bg={background}
          yPosition="bottom"
          xPosition="left"
        />
        <RoundedCorner
          canvasHeight={160}
          bg={background}
          yPosition="bottom"
          xPosition="right"
        />

        <Stack spacing={{ base: '80px', lg: '120px' }}>
          <Box
            id="auth-user-components"
            visibility="hidden"
            pos="relative"
            top={-250}
          />

          <AuthUserComponents />

          <ModernWeb />

          <Box
            id="customization"
            visibility="hidden"
            pos="relative"
            top={-250}
          />
          <Customization />
          <WhyClerk />
        </Stack>
      </LayoutContainer>
      <Box bg="white">
        <Testimonials />
        {/* <BottomCTAV2 /> */}
      </Box>
    </Box>
  );
};

export default HomeV2;

export async function getStaticProps({ preview = false }) {
  return singlePageProps({ preview, slug: '/' });
}
